@import "@blueprintjs/core/lib/scss/variables";
@import "../../breakpoints";

.chartWrapper { 
  position: relative;
  width: 100%;
  height: 70vh;
  overflow: hidden;
}

@media (max-height: $bp-lg) {
  .chartWrapper {
    height: 65vh;
  }
}